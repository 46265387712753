import CustomImage from './CustomImage';

import { handleDownload } from './RenderItemUtils';

import interiorOptions from 'utils/data/interiorOptions';

import { useState, useEffect, memo } from 'react';

import { trackEvent } from 'utils/analytics';
const { styles } = interiorOptions;
import { Transition } from '@headlessui/react';

import ActionPanel from './ActionPanel';
import ItemMenu from './ItemMenu';
import EditActionPanel from './EditActionPanel';

// create hashmap of names and display names from styles

const deviceWidths = [768, 768, 768];

const stylesMap = styles.reduce((acc, style) => {
  acc[style.name] = style.display_name;
  return acc;
}, {});

const RedesignedRoomComp = ({
  setActiveInput,
  render,
  subscription,
  openPricingModal,
  counter,
  imgURL,
  setDisplayImage,
  editable,
  editMode,
  handleEditMode,
  setOverrideRender,
  hijackAdvancedEditMode,
  isPriority = false
}) => {
  const [loaderOverride, setLoaderOverride] = useState(false);

  // const [imgURL, setDisplayImage] = useState(render?.output_url);

  const [inpaintingLoading, setInpaintingLoading] = useState(false);

  const handleEditModeChange = (renderId) => {
    if (!editMode) {
      setInpaintingLoading(true);
      handleEditMode(renderId);
    } else {
      handleEditMode(null);
    }
  };

  const tapHoldImg = (render, swap) => {
    trackEvent('before_after_tap_hold', {
      event_category: 'engagement',
      event_label: 'before_after_tap_hold'
    });
    if (!render) return;
    if (swap) {
      setDisplayImage(render.input_source_url);
    } else {
      if (render.output_high_url) setDisplayImage(render.output_high_url);
      else if (render.output_url) setDisplayImage(render.output_url);
    }
  };

  return (
    <div className="group shadow-md rounded-lg pb-3 bg-white relative z-40">
      <div className="aspect-w-4 aspect-h-3 overflow-hidden rounded-t-lg bg-white relative">
        {!render.output_url && (
          <span className="z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute opacity-40">
            <lottie-player
              src="processing.json"
              style={{ width: '100%' }}
              background="transparent"
              speed="2"
              loop
              autoplay
            ></lottie-player>
          </span>
        )}
        {render.output_url && !editMode && (
          <div
            className="bg-none p-4 text-sm absolute z-10 bottom-0 right-0  stop-click"
            onTouchStart={(event) => {
              tapHoldImg(render, true);
              event.stopPropagation();
            }}
            onTouchEnd={(event) => {
              tapHoldImg(render, false);
              event.stopPropagation();
              event.preventDefault();
            }}
            onMouseDown={(event) => {
              tapHoldImg(render, true);
              event.stopPropagation();
            }}
            onMouseUp={(event) => {
              tapHoldImg(render, false);
              event.stopPropagation();
              event.preventDefault();
            }}
            onClick={() => null}
            onContextMenu={(event) => event.preventDefault()}
          >
            <div className="bg-white rounded-lg flex align-center items-center opacity-80">
              <lottie-player
                src="/tap2.json"
                style={{
                  width: '45px',
                  pointerEvents: 'none',
                  marginRight: '-10px',
                  marginTop: '-2px'
                }}
                background="transparent"
                speed="0.5"
                count={3}
                loop
                autoplay
              ></lottie-player>
              <div className="ml-0 mr-2 text-gray-500 text-xs font-medium">
                Tap Here
              </div>
            </div>
          </div>
        )}
        {render.output_url && !editMode && (
          <div className="bg-none p-4 text-sm absolute z-10  right-0  stop-click">
            <ItemMenu
              render={render}
              setOverrideRender={setOverrideRender}
              setDisplayImage={setDisplayImage}
            />
          </div>
        )}
        {editMode ? (
          <Transition
            show={editMode}
            appear={true}
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            enter="transition ease-out duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            {/* <EditableCustomImage
              src={imgURL}
              altSrc={render?.input_source_url}
              widths={deviceWidths}
              aspectRatio={render?.aspect_ratio}
              style={{ width: '100%' }}
              editMode={editMode}
              alt={`A ${render?.room_type} in the ${render?.style} interior design style as redesigned by AI for inspiration and ideas`}
              className={`object-cover min-h-[250px] min-w-full stop-click ${
                render?.output_url ? '' : 'grayscale'
              }`}
              loaderOverride={loaderOverride}
              inpaintingLoading={inpaintingLoading}
              setInpaintingLoading={setInpaintingLoading}
              isPriority={isPriority}
            /> */}
          </Transition>
        ) : (
          <Transition
            show={!editMode}
            leave="transition ease-in duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            appear={true}
            enter="transition ease-out duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            {/* <img
              src={imgURL}
              altsrc={render?.input_source_url}
              className={`object-cover min-h-[250px] min-w-full stop-click ${
                render?.output_url ? '' : 'grayscale'
              }`}
            ></img> */}

            <CustomImage
              src={imgURL}
              altSrc={render?.input_source_url}
              widths={deviceWidths}
              aspectRatio={render?.aspect_ratio}
              style={{ width: '100%' }}
              editMode={editMode}
              alt={`A ${render?.room_type} in the ${render?.style} interior design style as redesigned by AI for inspiration and ideas`}
              className={`object-cover min-w-full stop-click ${
                render?.output_url ? '' : 'grayscale'
              }`}
              loaderOverride={loaderOverride}
              inpaintingLoading={inpaintingLoading}
              setInpaintingLoading={setInpaintingLoading}
              isPriority={isPriority}
              hideWatermark={!!subscription}
            />
          </Transition>
        )}
      </div>
      {true ? (
        <ActionPanel
          render={render}
          imgURL={imgURL}
          editMode={editMode}
          handleEditMode={handleEditMode}
          subscription={subscription}
          setActiveInput={setActiveInput}
          openPricingModal={openPricingModal}
          handleDownload={handleDownload}
          editable={editable}
          stylesMap={stylesMap}
          handleEditModeChange={handleEditModeChange}
          hijackAdvancedEditMode={hijackAdvancedEditMode}
          counter={counter}
        />
      ) : (
        <EditActionPanel
          render={render}
          stylesMap={stylesMap}
          imgURL={imgURL}
          counter={counter}
          editMode={editMode}
          handleEditMode={handleEditMode}
          setActiveInput={setActiveInput}
          subscription={subscription}
          openPricingModal={openPricingModal}
          handleDownload={handleDownload}
          inpaintingLoading={inpaintingLoading}
          setInpaintingLoading={setInpaintingLoading}
          setDisplayImage={setDisplayImage}
          editable={editable}
          setLoaderOverride={setLoaderOverride}
          handleEditModeChange={handleEditModeChange}
          setOverrideRender={setOverrideRender}
        />
      )}
    </div>
  );
};

const RedesignedRoom = memo(RedesignedRoomComp);

export default RedesignedRoom;
